// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-artist-tsx": () => import("./../../../src/pages/artist.tsx" /* webpackChunkName: "component---src-pages-artist-tsx" */),
  "component---src-pages-delivery-tsx": () => import("./../../../src/pages/delivery.tsx" /* webpackChunkName: "component---src-pages-delivery-tsx" */),
  "component---src-pages-faqs-tsx": () => import("./../../../src/pages/faqs.tsx" /* webpackChunkName: "component---src-pages-faqs-tsx" */),
  "component---src-pages-from-tsx": () => import("./../../../src/pages/from.tsx" /* webpackChunkName: "component---src-pages-from-tsx" */),
  "component---src-pages-gift-tsx": () => import("./../../../src/pages/gift.tsx" /* webpackChunkName: "component---src-pages-gift-tsx" */),
  "component---src-pages-gifts-tsx": () => import("./../../../src/pages/gifts.tsx" /* webpackChunkName: "component---src-pages-gifts-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-info-tsx": () => import("./../../../src/pages/info.tsx" /* webpackChunkName: "component---src-pages-info-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-to-tsx": () => import("./../../../src/pages/to.tsx" /* webpackChunkName: "component---src-pages-to-tsx" */)
}

